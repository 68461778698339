@import './fonts';

.keyword-div {
  border-radius: 6px;
  box-shadow: 4px 4px 18px 5px rgba(0,0,0,0.13);
  margin-bottom: 110px;
  background-color: white;

  .keyword-row {
    box-shadow: 2px 17px 37px -27px rgba(0,0,0,0.15)
  }

  .error-msg {
    color: rgba(103, 133, 259, 0.8);
    font-family: 'ProximaNova-Semibold';
    text-align: center;
    width: 100%;
    margin-bottom: 30px;
    margin-top: 60px;
    font-size: 18px;
    letter-spacing: 0.5px;
  }

  .keyword-col {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 30px 30px 15px;
  }

  .keyword {
    margin-right: 15px;
    margin-bottom: 15px;
    padding-left: 26px;
    padding-right: 26px;
    padding-top: 9px,;
    padding-bottom: 9px; 
    background-color: #EDF6FE; 
    color: #2F95F6;
    font-size: 0.96rem;
    text-transform: uppercase;
    border-radius: 14px;
    font-family: 'ProximaNova-Semibold';
    letter-spacing: 1.5px;
    transition: background-color .3s ease;
  
    &:hover {
      cursor: pointer;
      background-color: #d8ebfd;
    }
  }

  .and {
    font-family: 'ProximaNova-Light' !important;
    font-size: 0.8rem !important;
    padding-right: 5px;
    padding-left: 5px;
  }
}

@media only screen 
  and (max-width: 575px) {
    .keyword-row {
      margin-bottom: 0px !important;

      > div {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
      }
      
    }

    .keyword {
      font-size: 0.84rem !important;
      padding-left: 13px !important;
      padding-right: 13px !important;
      padding-top: 6px !important;
      padding-bottom: 6px !important;
      margin-right: 8px !important;
      margin-bottom: 14px !important;
    }

    .and {
      padding-right: 2px !important;
      padding-left: 2px !important;
    }
  }