@import './fonts';

.footer-row {
  margin-bottom: 25px;
  color: white;
  display: flex;
  align-items: flex-start;

  .footer-el {
    margin-left: 24px;
    text-transform: uppercase;
    font-family: 'ProximaNova-Semibold';
    font-size: 0.84rem;
    letter-spacing: 0.3px;

    > a {
      color: white;
    }
  }
  
  .footer-logo {
    width: 68px;
    //margin-left: -60px;
    padding-bottom: 10px;
  }
  
  .statement {
    margin-left: 40px;
    font-size: 0.84rem
  }
}

@media only screen 
  and (max-width: 767px) {
    .footer-logo {
      margin-left: 0px !important;
      margin-bottom: 6px;
    }

    .footer-el {
      margin-left: 0px !important;
      margin-top: 6px;
      font-size: 0.86rem !important;
    }

    .statement {
      margin-left: 0px !important;
      margin-top: 14px;
      font-size: 1rem !important;
    }
  }

@media only screen 
  and (min-width: 767px) and (max-width: 991px) {
    .footer-logo {
      margin-left: 0px !important;
      //margin-bottom: 0px !important;
      padding-bottom: 0 !important;
      padding-top: 2px !important;
    }

    .footer-el {
      margin-left: 0px !important;
      margin-top: 6px;
      font-size: 0.86rem !important;
    }

    .statement {
      margin-left: 0px !important;
      margin-top: 14px;
      font-size: 1rem !important;
    }
  }