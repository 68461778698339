@import './fonts.scss';

.top-logo {
  width: 106px;
  height: auto;
  margin-top: 48px
}

.mobile-top-logo {
  opacity: 0.5
}

.title-break {
  display: none;
}

.title-row {
  margin-top: 6px;
  margin-bottom: 45px;

  .title {
    text-align: center;
    font-size: 1.6rem;
    line-height: 2.3rem;
    letter-spacing: 1.1px;
    font-family: 'ProximaNova-Bold';
    color: white;
    margin-left: auto;
    margin-right: auto;
    width: 40vw;
  }

  .reddit {
    background-color: rgb(153, 181, 254);
    padding-left: 3.5px;
    padding-right: 3px;
    margin-right: 3px;
  }
}

@media only screen 
  and (min-width: 320px) and (max-width: 377px) {
    .title {
      font-size: 1.5rem !important;
    }

    .title {
      width: 100% !important;
      text-align: left !important;
      margin-top: 30px !important;
    }
  }

@media only screen 
 and (max-width: 480px) {
  .top-logo {
    display: none;
  }

  .mobile-top-logo {
    width: 100px !important;
    margin-top: 48px !important;
  }

  .title-row {
    margin-top: 0px !important;
    margin-bottom: 28px !important;
    
    > div {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }

  .title {
    width: 100% !important;
    text-align: left !important;
    margin-top: 30px !important;
  }
 }

@media only screen 
and (min-width: 481px) and (max-width: 767px) {
    .top-logo {
      display: none;
    }

    .mobile-top-logo {
      width: 100px !important;
      margin-top: 48px !important;
    }

    .title-break {
      display: block !important;
    }

    .title-row {
      margin-top: 0px !important;
      margin-bottom: 28px !important;
      
      > div {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
    }

    .title {
      width: 100% !important;
      //text-align: left !important;
      margin-top: 30px !important;
    }

  }

@media only screen 
  and (min-width: 768px) and (max-width: 991px) {
    .top-logo {
      display: none;
    }

    .mobile-top-logo {
      width: 100px !important;
      margin-top: 48px !important;
    }

    .title-row {
      margin-top: 0px !important;
      margin-bottom: 28px !important;
      
      > div {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
    }

    .title {
      width: 60vw !important;
      text-align: center !important;
      margin-top: 30px !important;
    }
  }

@media only screen 
  and (min-width: 992px) and (max-width: 1023px) {
    .title {
      width: 60vw !important;
    }

    .title-row {
      margin-bottom: 25px;
    }

    .top-logo {
      margin-top: 28px;
    }

    .mobile-top-logo {
      display: none;
    }
  }

@media only screen 
  and (min-width: 1024px) {
    .title {
      width: 500px !important;
    }

    .title-row {
      margin-bottom: 28px;
    }
  }

@media only screen 
  and (min-width: 992px) {
    .mobile-top-logo {
      display: none;
    }

  }
