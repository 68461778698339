@import './fonts';

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity .3s ease;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity .3s ease;
}

.chart-row {
  margin-bottom: 20px;
  background-color: white;
  padding: 20px;
  box-shadow: 4px 4px 18px 5px rgba(0,0,0,0.13);
}

.spinner-col {
  display: flex;
  z-index: 99;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: -20px;
  margin-left: -20px;
  border-radius: 6px;
}

.blue-logo {
  height: 13px;
  opacity: 0.3;
}

.info {
  width: 14px;
  margin-left: 10px;
  margin-bottom: 3px;

  &:hover {
    cursor: pointer;
  }
}

.chart-title {
  margin-bottom: 0 !important;
  font-family: 'ProximaNova-Semibold';
  font-size: 1.3rem;
}

.title-col {
  padding-left: 9px;
}

.second-row {
  margin-bottom: 10px;

  > div {
    color: #B7B7B7;
    font-size: 0.95rem;
    padding-left: 9px;
    font-family: 'ProximaNova-Semibold';
    letter-spacing: 0.1px;
  }
}

.separator {
  margin-left: 8px;
  margin-right: 8px;
}

.chart-top {
  display: flex;
  align-items: stretch;

  .title-wrapper {
    float: right;
  }

  .action-wrapper {
    padding-left: 4px;
    padding-right: 4px;
    background-color: #EAF2FE;
    border-radius: 6px;
    margin-left: 10px;
    display: inline-block;
    
  }

  .hide-on-mobile {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .chart-icon-span {
    display: inline-block;
    align-items: center;
    justify-content: center;
    width: 42px !important;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 1px;
    padding-bottom: 1px;
    margin-left: 2px;
    margin-right: 2px;
    transition: background-color 0.3s ease;
    border-radius: 6px;

    &:hover {
      cursor: pointer;
      background-color: #d1ddf0;
      border-radius: 6px;
    }
  }

  .chart-icon {
    width: 14px;
    margin-left: 2px;
    margin-right: 2px;
  }

  .embed {
    width: 16px !important;
  }

  .share {
    width: 12px !important;
  }

  .download {
    width: 14px !important;
  }

  .checkbox {
    width: 18px;
    height: 18px;
    margin-left: 10px;
    border: 2px solid #9FC5FD;
    display: inline-block;
    border-radius: 3px;
    vertical-align: middle;
    margin-top: -2px;

    &:hover {
      cursor: pointer;
    }
  }

  .empty {
    background-color: white;
  }

  .checked {
    background-color: #9FC5FD;
  }

  .check {
    height: 9px;
    vertical-align: top;
    margin-top: 3px;
    margin-left: 0.9px;
  }

  .smooth {
    color: #9FC5FD;
    text-transform: uppercase;
    font-family: 'ProximaNova-Semibold';
    font-size: 0.85rem;
    line-height: 29px;
    letter-spacing: 1px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
}

@media only screen 
  and (max-width: 356px) {
    .action-col {
      min-width: 100% !important;
    }

    .title-wrapper {
      float: left !important;
    }

    .action-wrapper {
      margin-left: 0 !important;
    }
  }

@media only screen 
  and (max-width: 426px) {
    .separator {
      display: none;
    }
  }

@media only screen 
  and (max-width: 575px) {
    .title-col {
      padding-left: 0 !important;
    }

    .second-row {
      > div {
        padding-left: 0 !important;
      }
    }

    .action-col {
      padding-left: 0 !important;
    }

    .spinner-col {
      margin-left: 0 !important;
    }

    .hide-on-mobile {
      display: none !important;
    }

  }

@media only screen 
  and (min-width: 576px) and (max-width: 767px) {

    .title-col {
      padding-left: 0 !important;
    }

    .second-row {
      > div {
        padding-left: 0 !important;
      }
    }

    .action-wrapper {
      float: right;
    }

    .hide-on-mobile {
      display: none !important;
    }

    .smooth {
      padding-bottom: 2.5px !important;
      padding-top: 2.5px !important;
    }
  }
