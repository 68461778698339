.csv-spinner {
  margin: 0 auto;
}

.csv-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.downloading {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

.csv-email-input-group {
  margin-top: 1.5vh;
}

.download-col {
  justify-content: center;
}

.download-now {
  height: 38px;
  width: 100%;
  border-radius: 3px;
  background-color: rgb(96, 137, 249);
  color: white;
  border: none;
  text-transform: uppercase;
  font-family: 'ProximaNova-Semibold';
  letter-spacing: 1.2px;
  font-size: 0.9rem;
  padding-top: 7px;
  padding-left: 24px;
  padding-right: 24px;
}

@media only screen 
  and (max-width: 576px) {
    .csv-email {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }


  .react-tiny-popover-container {
    z-index: 100;
    max-width: none;
    padding: 38px;
    background-color: white;
    box-shadow: 5px 4px 20px 6px rgba(0, 0, 0, 0.13);
    border-radius: 6px;
    overflow: visible !important;

    > div {
      > p {
        letter-spacing: 0.5px;
        color: #A2A2A2;
        text-align: center;
        font-size: 1rem;
        margin-bottom: 28px;
      }

      > div:nth-child(1) {
        border-left: 25px solid rgba(255, 255, 255, 0) !important;
        border-right: 25px solid rgba(255, 255, 255, 0) !important;
        border-bottom: 20px solid rgb(255, 255, 255) !important;
        top: -20px !important;
        left: 191.906px !important;
        z-index: 1000 !important;
      }
    }    
  }
