@import './fonts';

.subscribe-row {
  padding-top: 40px;
  padding-bottom: 40px;

  .subscribe-col {
    .subscribe-div {
      margin-right: 20px;

      > div {
        float: right;
      }
    }

    .input-col {
      margin-left: 20px;
    }
  }

  .subscribe {
    text-transform: capitalize;
    letter-spacing: 1px;
    text-align: center;
    font-family: 'ProximaNova-Semibold';
    color: rgb(131, 131, 131);
    font-size: 1.3rem;
    padding-bottom: 6px;
    padding-top: 6px;
    margin-bottom: 0px;
  }

  .unsubscribe {
    font-family: 'ProximaNova-Light';
    text-align: center;
    color: rgb(131, 131, 131);
    margin-bottom: 0;
  }

  .email-input-group {
    float: left;
    max-width: 362px;
    margin-top: 1.5vh;
    margin-left: 10px;
  }
  
}

.email-input {
  background-color:#EFEFEF;
  color: #606060;
  border: none;
  height: 44px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  font-size: 1.1rem;
  padding-left: 20px;
  letter-spacing: 0.8px;
  vertical-align: middle;

  &:focus {
    background-color:#EFEFEF;
    color: #606060;
    box-shadow: none;
  }

  &::-webkit-input-placeholder {
    color: #606060;
    letter-spacing: 0.8px;
    font-size: 1.1rem;
  }
}

.submit {
  background-color: rgb(96, 137, 249);
  color: white;
  border: none;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  text-transform: uppercase;
  font-family: 'ProximaNova-Semibold';
  padding-left: 30px;
  padding-right: 30px;
  letter-spacing: 1.2px;
  font-size: 1rem;

  &:focus {
    border: none;
    box-shadow: none;
  }
}

@media only screen 
  and (max-width: 576px) {
      
    .subscribe {
      font-size: 1.1rem !important;
    }

    .subscribe-col {
      padding-left: 4px !important;
      padding-right: 4px !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-top: 20px !important;
      text-align: center !important;
      margin-bottom: 14px;

      .subscribe-div {
        float: none !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }

    .appendix {
      display: none;
    }

    .input-col {
      margin-left: 0 !important;
    }

    .email-input-group {
      max-width: 100% !important;
      margin-left: 0 !important;
    }

    .email-input {
      margin-left: 20px;
      margin-right: 20px;
      height: 38px !important;
      text-align: center;
      padding-left: 12px !important;
      border-radius: 3px !important;
    }

    .mobile-submit-col {
      margin-top: 13px !important;
      margin-bottom: 23px;
    }

    .mobile-submit {
      height: 38px;
      width: 100%;
      margin-right: 20px;
      margin-left: 20px;
      border-radius: 3px;
      background-color: rgb(96, 137, 249);
      color: white;
      border: none;
      text-transform: uppercase;
      font-family: 'ProximaNova-Semibold';
      letter-spacing: 1.2px;
      font-size: 0.92rem;

      &:focus {
        border: none;
        box-shadow: none;
      }
    }
  }

@media only screen 
    and (min-width: 576px) {
      .mobile-submit-col {
        display: none !important;
      }
    }

@media only screen 
  and (min-width: 576px) and (max-width: 991px) {
    .subscribe-row {
        padding-top: 10px !important;
      
        .subscribe {
        font-size: 1.1rem !important;
      }

      .subscribe-col {
        padding-left: 4px !important;
        padding-right: 4px !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 20px !important;
        text-align: center !important;
        margin-bottom: 14px;

        .subscribe-div {
          float: none !important;
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }

      .email-input-group {
        max-width: 100% !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
        
      }

      .email-input {
        margin-left: 0px !important;
        margin-right: 0px !important;
        height: 38px !important;
        text-align: center;
        padding-left: 12px !important;
        border-radius: 3px !important;
      }
    }
  }