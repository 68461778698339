@import './fonts.scss';
@import './subscribe.scss';
@import './footer.scss';
@import './bottom-part.scss';
@import './keywords.scss';
@import './search.scss';
@import './header.scss';
@import './chart.scss';
@import './modal-info.scss';
@import './modal-embed.scss';
@import './modal-share.scss';
@import './popover-download.scss';

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

body {
  overflow-x: hidden !important;
  font-family: 'ProximaNova';
}

@media only screen 
  and (max-width: 575px){
    .topbg {
      background-position: 90% 0 !important;
      height: 30% !important;
      background-size: cover !important;
    }

    .bottombg {

    }
  }

@media only screen 
  and (min-width: 576px) and (max-width: 991px) {
    .topbg {
      background-position: 90% 0 !important;
      height: 30% !important;
      background-size: cover !important;
    }
  }



@media only screen 
  and (min-width: 992px) and (max-width: 1199px){
    .topbg {
      background-position: top !important;

    }
  }

  @media only screen 
  and (min-width: 1200px) {
    .topbg {
      height: 33% !important;
    }
  }

.bottombg {
  width: 100%;
  height: 48%;
  position: absolute;
  bottom: 0;
  z-index: -100;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}

.topbg {
  width: 100%;
  height: 35%;
  position: absolute;
  top: 0;
  z-index: -100;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
}