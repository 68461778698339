@import './fonts';

.info-row {
  margin-bottom: 80px;
  padding-left: 4%;
  padding-right: 4%;

  .grid-col {
    padding-right: 30px;
  }

  .grid-img {
    width: 100%;
    float: right;
    max-height: 380px;
    height: auto;
  }

  .text-col {
    color: white;
    padding-left: 30px;
  }

  .info-title {
    margin-bottom: 40px;
    font-family: 'ProximaNova-Bold';
    font-size: 1.6rem;
    letter-spacing: 1.1px;
  }

  .info-text {
    letter-spacing: 0.5px;
    font-size: 1.09rem;
  }

}

@media only screen 
  and (max-width: 575px) {
    .info-row {
      margin-bottom: 80px !important;
    }

    .text-col {
      padding-left: 0 !important;
    }

    .info-text {
      font-size: 1.2rem !important;
      margin-bottom: 1.4rem !important;
    }

    .info-title {
      margin-bottom: 26px !important;
    }

    .grid-col {
      display: none;
    }
  }

  @media only screen 
    and (min-width: 576px) and (max-width: 991px) {
      .grid-col {
        display: none !important;
      }
    }