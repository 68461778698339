@font-face {
  font-family: 'ProximaNova';
  src:
    local('ProximaNova'),
    local('ProximaNova-Regular'),
    url(../fonts/ProximaNova-Regular.woff) format('woff'),
    url(../fonts/ProximaNova-Regular.svg) format('svg'),
    url(../fonts/ProximaNova-Regular.eot) format('embedded-opentype'),
    url(../fonts/ProximaNova-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'ProximaNova-Bold';
  src:
    local('ProximaNova-Bold'),
    url(../fonts/ProximaNova-Bold.woff) format('woff'),
    url(../fonts/ProximaNova-Bold.svg) format('svg'),
    url(../fonts/ProximaNova-Bold.eot) format('embedded-opentype'),
    url(../fonts/ProximaNova-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'ProximaNova-Light';
  src:
    local('ProximaNova-Light'),
    //url(../fonts/ProximaNova-Light.woff) format('woff'),
    url(../fonts/ProximaNova-Light.svg) format('svg'),
    url(../fonts/ProximaNova-Light.eot) format('embedded-opentype'),
    url(../fonts/ProximaNova-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'ProximaNova-Semibold';
  src:
    local('ProximaNova-Semibold'),
    url(../fonts/ProximaNova-Semibold.woff) format('woff'),
    url(../fonts/ProximaNova-Semibold.svg) format('svg'),
    url(../fonts/ProximaNova-Semibold.eot) format('embedded-opentype'),
    url(../fonts/ProximaNova-Semibold.ttf) format('truetype');
}