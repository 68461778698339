@import './fonts';

.close {
  width: 18px;

  &:hover {
    cursor: pointer;
  }
}

.modal-close {
  position: absolute;
  right: 26px;
  top: 26px;
  width: 18px;

  &:hover {
    cursor: pointer;
  }
}

.modal-content {
  border-radius: 6px;
  box-shadow: 4px 4px 18px 5px rgba(0,0,0,0.13);
}

.modal-title {
  font-family: 'ProximaNova-Semibold';
  font-size: 1.3rem;
  margin-bottom: 24px;
}

.modal-wrapper {
  padding: 38px;
}

.info-modal-row {
  display: flex;
  align-items: stretch;
}

.explanation {
  padding-right: 40px;
  line-height: 1.7rem;
  letter-spacing: 0.5px;
  color: #A2A2A2;
}

@media only screen 
  and (max-width: 575px) {
    .explanation {
      padding-right: 0px !important;
    }
  }