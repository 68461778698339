@import './fonts';

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 960px !important;
  }
}

.diagram {
  width: 100%;
  border-radius: 6px;
}

.code-div {
  box-shadow: 3px 3px 14px 1px rgba(0,0,0,0.07);
  border-radius: 6px;
  padding: 30px;

  > p {
    margin-bottom: 0 !important;
    color: #A2A2A2;
    font-size: 0.9rem;
    line-height: 1.3rem;
  }
 
}

.grey {
  color: #A2A2A2;
}

.diagram-div {
  box-shadow: 3px 3px 14px 1px rgba(0,0,0,0.07);
  border-radius: 6px;
  min-height: 400px;
}

@media only screen 
  and (max-width: 991px) {
    .code-col {
      margin-bottom: 40px;
    }
  }
